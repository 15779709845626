import { setupServiceInfo, getModuleFederatedName } from '@estee/elc-universal-utils';
import { ServiceManager } from '@estee/elc-service';
import serviceNames, { AnalyticsService } from '~setup/AnalyticsService';
import { serviceBusBaseClass } from '~setup/ServiceBus';

setupServiceInfo(__serviceInfo__);

const { name, version } = __serviceInfo__;

AnalyticsService.setViews(name, getModuleFederatedName(name), version, []);

ServiceManager.setServiceStartParams({
    serviceName: name,
    diContainerImport: () => import('~setup/diContainer'),
    serviceKey: serviceNames.analyticsService,
    serviceBusControllerKey: serviceNames.serviceBusController,
    serviceBusBaseClass
});
