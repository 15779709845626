import { ServiceManager, ServiceBusController, ServiceBusControllerBody } from '@estee/elc-service';
import { events, on } from '@estee/elc-service-bus';

const onEvents = [
    [events.ACCOUNT_SIDEBAR_OVERLAY_OPENED, 'accountSidebarOverlayOpened'],
    [events.ACCOUNT_LOYALTY_POINTS_INFO_LINK_CLICKED, 'accountLoyaltyPointsInfoLinkClicked'],
    [
        events.ACCOUNT_NONLOYALTY_ENROLLMENT_SIGNUP_CLICKED,
        'accountNonLoyaltyEnrollmentSignupClicked'
    ],
    [events.ACCOUNT_NONLOYALTY_ENROLLMENT_LINK_CLICKED, 'accountNonLoyaltyEnrollmentLinkClicked'],
    [events.ACCOUNT_SIDEBAR_LOYALTY_LINK_CLICKED, 'accountSidebarLoyaltyClicked'],
    [events.ACCOUNT_SIDEBAR_MENU_LINK_CLICKED, 'accountSidebarMenuClicked'],
    [events.ACCOUNT_SIDEBAR_SHOWCASE_LINK_CLICKED, 'accountSidebarShowcaseClicked'],
    [events.ADDRESS_SELECTION_CHANGED, 'shippingAddressFilled'],
    [events.ANALYTICS_LOADED, 'analyticsLoaded'],
    [events.ANALYTICS_PAGE_VIEWED, 'pageViewed'],
    [events.APOOINTMENT_PROCESS_COMPLETED, 'appointmentProcessCompleted'],
    [events.APPOINTMENT_ADDED_TO_CALENDAR, 'appointmentAddedToCalendar'],
    [events.APPOINTMENT_ARTIST_SELECTED, 'appointmentArtistSelected'],
    [events.APPOINTMENT_DATE_TIME_SELECTED, 'appointmentDateTimeSelected'],
    [events.APPOINTMENT_EDIT_CLICKED, 'appointmentEditClicked'],
    [events.APPOINTMENT_SERVICE_SELECTED, 'appointmentServiceSelected'],
    [events.APPOINTMENT_STARTED_OVER, 'appointmentStartedOver'],
    [events.APPOINTMENT_STEP_LOADED, 'appointmentStepLoaded'],
    [events.APPOINTMENT_VIRTUAL_SELECTED, 'appointmentVirtualSelected'],
    [events.APPOINTMENT_LOCATION_SELECTED, 'appointmentLocationSelected'],
    [events.APPOINTMENT_REVIEW_WIDGET_CLICK, 'appointmentReviewWidgetClick'],
    [events.APPOINTMENT_REVIEW_PAGINATION, 'appointmentReviewPagination'],
    [events.APPOINTMENT_REVIEW_WIDGET_FILTERS, 'appointmentReviewWidgetFilters'],
    [events.APPOINTMENT_ACCOUNT_BUTTON_CLICKED, 'appointmentAccountButtonClicked'],
    [events.APPOINTMENT_CALL_STORE_CLICKED, 'appointmentCallStoreClicked'],
    [events.ASK_AND_ANSWER_CLICKED, 'askAndAnswerClicked'],
    [events.ASK_AND_ANSWER_HELPFUL_NO_CLICKED, 'askAndAnswerHelpfulNoClicked'],
    [events.ASK_AND_ANSWER_HELPFUL_YES_CLICKED, 'askAndAnswerHelpfulYesClicked'],
    [events.ASK_AND_ANSWER_SEARCH_CLICKED, 'askAndAnswerSearchClicked'],
    [events.ASK_AND_ANSWER_SHOW_MORE_CLICKED, 'askAndAnswerShowMoreClicked'],
    [events.ASK_AND_ANSWER_SUBMITTED, 'askAndAnswerSubmitted'],
    [events.ASK_AND_ANSWERS_SORT_BY_CHANGED, 'askAndAnswerSortByChanged'],
    [events.BOOK_APPOINTMENT_CLICKED, 'bookAppointmentClicked'],
    [events.BOOK_VIRTUAL_APPOINTMENT_CLICKED, 'bookVirtualAppointmentClicked'],
    [events.CART_LOGGED_IN, 'cartLoggedIn'],
    [events.CART_OVERLAY_CLOSED, 'cartOverlayClosed'],
    [events.CART_OVERLAY_OFFER_CLICKED, 'cartOverlayOfferClicked'],
    [events.CART_OVERLAY_OFFER_VIEWED, 'cartOverlayOfferViewed'],
    [events.CART_OVERLAY_OPENED, 'cartOverlayOpened'],
    [events.CART_OVERLAY_TAB_CLICKED, 'cartTabClicked'],
    [events.CART_PAGE_VIEW, 'cartPageView'],
    [events.CREATE_ACCOUNT_ATTEMPT, 'createAccountAttempt'],
    [events.CREATE_ACCOUNT_FAIL, 'createAccountFail'],
    [events.GET_DIRECTIONS_CLICKED, 'getDirectionsClicked'],
    [events.GRID_FILTERED, 'filterGrid'],
    [events.GRID_SORTED, 'sortGrid'],
    [events.ITEM_ADDED, 'itemAdded'],
    [events.LIVE_CHAT_INTERFACE_CLICKED, 'liveChatInterfaceClicked'],
    [events.MANUAL_OFFER_CODE_APPLIED, 'applyOfferCode'],
    [events.MPP_FILTERED, 'filterMpp'],
    [events.NAVIGATE, 'navigatedToPageView'],
    [events.OFFER_CODE_FAILED, 'offerCodeFailed'],
    [events.ORDER_CONFIRMATION_VIEW, 'orderConfirmationPageLanded'],
    [events.ORDER_CREATED, 'paymentMethodCompleted'],
    [events.PAYMENT_SUBMITTING, 'trackOrderDetails'],
    [events.PRODUCT_CLICKED, 'productClicked'],
    [events.PRODUCT_COMPARISON_VIEWED, 'productComparisonViewed'],
    [events.PRODUCT_FULL_VIEWED, 'productViewedUseCase'],
    [events.PRODUCT_GRID_VIEWED, 'productImpressionUseCase'],
    [events.PRODUCT_NOTIFY_ME_REQUEST_FAILURE, 'productNotifyMeRequestFailure'],
    [events.PRODUCT_NOTIFY_ME_REQUEST_SUCCESS, 'productNotifyMeRequestSuccess'],
    [events.PRODUCT_NOTIFY_ME_TRIGGERED, 'productNotifyMeTriggered'],
    [events.PRODUCT_SHADE_GROUP_SELECTED, 'shadeGroupSelected'],
    [events.PRODUCT_SKU_SELECTED, 'shadeChangeViewedUseCase'],
    [events.QUICK_VIEW_TRIGGERED, 'quickViewSelected'],
    [events.READ_REVIEWS_LINK_CLICKED, 'readReviewsEventTriggered'],
    [events.REMOVE_ITEM, 'removeItem'],
    [events.REVIEW_OVERLAY_CLOSED, 'reviewOverlayClosed'],
    [events.REVIEW_OVERLAY_OPENED, 'reviewOverlayOpened'],
    [events.REVIEWS_FILTER_CLICKED, 'reviewsFilterClicked'],
    [events.REVIEWS_FLAG_REVIEW_CLICKED, 'reviewsFlagReviewClicked'],
    [events.REVIEWS_HELPFUL_NO_CLICKED, 'reviewsHelpfulNoClicked'],
    [events.REVIEWS_HELPFUL_YES_CLICKED, 'reviewsHelpfulYesClicked'],
    [events.REVIEWS_SEARCH_CLICKED, 'reviewsSearchClicked'],
    [events.REVIEWS_SORT_BY_CHANGED, 'reviewsSortByChanged'],
    [events.WRITE_REVIEW_STARTED, 'writeReviewStarted'],
    [events.WRITE_REVIEWS_LINK_CLICKED, 'writeReviewEventTriggered'],
    [events.SAMPLE_ADDED, 'sampleAdded'],
    [events.SEARCH_ADDRESS_SUBMITTED, 'searchAddressSubmitted'],
    [events.SEARCH_PRODUCTS_QUERIED, 'searchProductsQueried'],
    [events.SEARCH_TERM_SELECTED, 'searchTermSelected'],
    [events.SEARCH_FILTERS_SELECTED, 'searchFiltersSelected'],
    [events.SHIPPING_OPTION_SELECTED, 'shippingOptionSelected'],
    [events.SKU_ENGRAVING_TRIGGERED, 'engravingTriggered'],
    [events.SKU_PICKER_CLOSED, 'skuPickerClosed'],
    [events.SKU_PICKER_OPENED, 'skuPickerOpened'],
    [events.SMARTGIFT_ADDED, 'smartGiftAdded'],
    [events.SMARTGIFT_CANCELLED, 'smartGiftCancelled'],
    [events.SMARTGIFT_HELP_CLOSED, 'smartgiftHelpClosed'],
    [events.SMARTGIFT_HELP_OPENED, 'smartgiftHelpOpen'],
    [events.SMARTGIFT_PREPARED, 'smartGiftPrepared'],
    [events.SMARTGIFT_REMOVED, 'smartGiftRemoved'],
    [events.SMARTGIFT_VIEWED, 'smartGiftViewed'],
    [events.SMARTGIFT_WIDGET_TOGGLED, 'smartGiftWidgetToggled'],
    [events.SOCIAL_SHARE_ICON_TRIGGERED, 'socialShareIconTriggered'],
    [events.STORE_DETAILS_CLICKED, 'storeDetailsClicked'],
    [events.STORE_INFO_CLICKED, 'storeInfoClicked'],
    [events.STORE_LOCATION_SELECTED, 'storeLocationSelected'],
    [events.STORE_MARKER_CLICKED, 'storeMarkerClicked'],
    [events.STORE_PHONE_CLICKED, 'storePhoneClicked'],
    [events.STORE_TOWN_SELECTED, 'storeTownSelected'],
    [events.SUBSCRIBED_FOR_EMAILS, 'subscribedForEvents'],
    [events.TRANSACTION_FAILED, 'transactionFailed'],
    [events.TRANSACTION_SUCCEDED, 'transactionSucceded'],
    [events.VTO_ALL_SHADES_VIEWED, 'vtoViewAllShades'],
    [events.VTO_BEST_SHADES_VIEWED, 'vtoViewBestShades'],
    [events.VTO_CAMERA_CLOSED, 'vtoCameraClosed'],
    [events.VTO_CAMERA_FAILED, 'vtoCameraFailed'],
    [events.VTO_CAMERA_OPENED, 'vtoCameraOpened'],
    [events.VTO_CLOSED, 'vtoClosed'],
    [events.VTO_COMPARE_DISABLED, 'vtoCompareDisabled'],
    [events.VTO_COMPARE, 'vtoCompare'],
    [events.VTO_ENABLED, 'vtoEnabled'],
    [events.VTO_ENGINE_CLOSED, 'vtoEngineClosed'],
    [events.VTO_FOUNDATION_QR_CODE_OVERLAY_CLOSED, 'vtoFoundationQrCodeOverlayClosed'],
    [events.VTO_FOUNDATION_QR_CODE_OVERLAY_OPENED, 'vtoFoundationQrCodeOverlayOpened'],
    [events.VTO_KISS_DETECTED, 'vtoKissDetected'],
    [events.VTO_LOADED, 'vtoLoaded'],
    [events.VTO_LOADING, 'vtoLoading'],
    [events.VTO_MATCH_PERSONALIZED, 'vtoMatchPersonalized'],
    [events.VTO_MATCH_REMOVED, 'vtoMatchRemoved'],
    [events.VTO_MATCH_SAVED, 'vtoMatchSaved'],
    [events.VTO_OPENED, 'vtoOpened'],
    [events.VTO_PAIR_SHADE_GRID_OPENED, 'vtoPairShadeGridOpened'],
    [events.VTO_PAIR_SHADE_SELECTED, 'vtoPairShadeSelected'],
    [events.VTO_PHOTO_LOADED, 'vtoPhotoLoaded'],
    [events.VTO_PHOTO_SAVED, 'vtoPhotoSaved'],
    [events.VTO_PRICE_SUPPRESSED, 'vtoPriceSuppressed'],
    [events.VTO_RESET, 'vtoReset'],
    [events.VTO_SHADE_FINDER_CANCELLED, 'vtoShadeFinderCancelled'],
    [events.VTO_SHADE_FINDER_COMPLETED, 'vtoShadeFinderCompleted'],
    [events.VTO_SHADE_FINDER_STARTED, 'vtoShadeFinderStarted'],
    [events.VTO_SHADE_REMINDER_OVERLAY_CLOSED, 'vtoShadeReminderOverlayClosed'],
    [events.VTO_SHADE_REMINDER_OVERLAY_OPENED, 'vtoShadeReminderOverlayOpened'],
    [events.VTO_SHADE_REMINDER_OVERLAY_RETAINED, 'vtoShadeReminderOverlayRetained'],
    [events.VTO_SHADE_REMINDER_OVERLAY_RETAKEN, 'vtoShadeReminderRetaken'],
    [events.VTO_SKIN_ANALYSIS_FINISHED, 'vtoSkinCareAnalysisFinished'],
    [events.VTO_SKIN_ANALYSIS_STARTED, 'vtoSkinCareAnalysisStarted'],
    [events.VTO_SKIN_BACK_TO_DETECTING, 'vtoSkinCareBackToDetecting'],
    [events.VTO_SKIN_CARE_CAMERA_CLOSED, 'vtoSkinCareCameraClosed'],
    [events.VTO_SKIN_CARE_CAMERA_FAILED, 'vtoSkinCareCameraFailed'],
    [events.VTO_SKIN_CARE_CAMERA_OPENED, 'vtoSkinCareCameraOpened'],
    [events.VTO_SKIN_CARE_CLOSED, 'vtoSkinCareClosed'],
    [events.VTO_SKIN_CARE_LOADED, 'vtoSkinCareLoaded'],
    [events.VTO_SKIN_CARE_LOADING, 'vtoSkinCareLoading'],
    [events.VTO_SKIN_CARE_OPENED, 'vtoSkinCareOpened'],
    [events.VTO_SKIN_CARE_PRODUCTS_LOADED, 'vtoSkinCareProductsLoaded'],
    [events.VTO_SKIN_CARE_VIDEO_LOADED, 'vtoSkinCareVideoLoaded'],
    [events.VTO_SKIN_SHADE_FOUND, 'vtoSkinShadeFound'],
    [events.VTO_SPLIT_FOUR_WAY_DISABLED, 'vtoSplitFourWayDisabled'],
    [events.VTO_SPLIT_FOUR_WAY_ENABLED, 'vtoSplitFourWay'],
    [events.VTO_SURVEY_LOADED, 'vtoSurveyLoaded'],
    [events.VTO_SURVEY_IGNORED, 'vtoSurveyIgnored'],
    [events.VTO_SURVEY_SUBMITTED, 'vtoSurveySubmitted'],
    [events.VTO_VIDEO_LOADED, 'vtoVideoLoaded'],
    [events.VTO_ZOOM, 'vtoZoom'],
    [events.VTO_TUTORIAL_STARTED, 'vtoTutorialStarted'],
    [events.VTO_TUTORIAL_UNMUTED, 'vtoTutorialUnmuted'],
    [events.VTO_TUTORIAL_MUTED, 'vtoTutorialMuted'],
    [events.VTO_TUTORIAL_PAUSED, 'vtoTutorialPaused'],
    [events.VTO_TUTORIAL_STOPPED, 'vtoTutorialStopped8'],
    [events.VTO_TUTORIAL_RESUMED, 'vtoTutorialResumed'],
    [events.VTO_TUTORIAL_PLAY_SPEED_UPDATED, 'vtoTutorialPlaySpeedUpdated'],
    [events.VTO_TUTORIAL_PREVIOUS_STEP, 'vtoTutorialPreviousStep'],
    [events.VTO_TUTORIAL_NEXT_STEP, 'vtoTutorialNextStep'],
    [events.VTO_TUTORIAL_LOOK_SELECTED, 'vtoLookSelected'],
    [events.VTO_TUTORIAL_PRODUCT_LIST_CLICKED, 'vtoProductListClicked'],
    [events.VTO_TUTORIAL_PRODUCTS_USED_ITEM_CLICKED, 'vtoProductsUsedItemClicked'],
    [events.VTO_BROW_FACE_ATTRIBUTE_PREDICTION_STARTED, 'vtoFaceAttributePredictionStarted'],
    [events.VTO_BROW_FACE_ATTRIBUTE_UPDATED, 'vtoFaceAttributeUpdated'],
    [events.VTO_BROW_GOAL_SELECTED, 'vtoBrowGoalSelected'],
    [events.VTO_BROW_TIPS_CLICKED, 'vtoTipsClicked'],
    [events.VTO_BROW_BACK_TO_GOALS_CLICKED, 'vtoBackToGoalsClicked'],
    [events.VTO_BROW_SHAPE_CHANGED, 'vtoShapeChanged'],
    [events.VTO_BROW_SHADE_TRY_ON, 'vtoBrowShadeTryOn'],
    [events.VTO_BROW_LENGTH_CHANGED, 'vtoLengthChanged'],
    [events.VTO_BROW_THICKNESS_CHANGED, 'vtoThicknessChanged'],
    [events.VTO_BROW_OPACITY_CHANGED, 'vtoOpacityChanged'],
    [events.VTO_BROW_GOLDEN_RATION_ENABLED, 'vtoGoldenRatioEnabled'],
    [events.VTO_BROW_GOLDEN_RATION_DISABLED, 'vtoGoldenRatioDisabled'],
    [events.VTO_BROW_STENCIL_ENABLED, 'vtoStencilEnabled'],
    [events.VTO_BROW_STENCIL_DISABLED, 'vtoStencilDisabled'],
    [events.VTO_BROW_COMPARE_ENABLED, 'vtoCompareEnabled'],
    [events.VTO_BROW_CAPTURE_CLICKED, 'vtoCaptureClicked'],
    [events.VTO_BROW_SEE_ALL_SHADES_CLICKED, 'vtoSeeAllShadesClicked'],
    [events.VTO_BROW_GET_BROW_CLICKED, 'vtoGetBrowClicked'],
    [events.LOYALTY_LOADMORE_LINK_CLICKED, 'loyaltyLoadMoreLinkClicked'],
    [events.LOYALTY_ONLINE_ORDER_NUMBER_CLICKED, 'loyaltyOnlineOrderNumberClicked'],
    [events.LOYALTY_SHOP_NOW_CLICKED, 'loyaltyShopNowClicked'],
    [events.LOYALTY_ALL_BENEFITS_CLICKED, 'loyaltyAllBenefitsClicked'],
    [events.LOYALTY_BENEFITS_REDEEM_CLICKED, 'loyaltyBenefitsRedeemClicked'],
    [events.LOYALTY_BENEFITS_REMOVE_CLICKED, 'loyaltyBenefitsRemoveClicked'],
    [events.LOYALTY_REWARDS_REDEEM_CLICKED, 'loyaltyRewardsRedeemClicked'],
    [events.LOYALTY_REWARDS_REMOVE_CLICKED, 'loyaltyRewardsRemoveClicked'],
    [events.LOYALTY_REWARDS_TAB_CLICKED, 'loyaltyRewardsTabClicked'],
    [events.STORE_DELIVERY_TYPE_SELECTED, 'storeDeliveryTypeSelected'],
    [events.STORE_SEARCH_ADDRESS_SUBMITTED, 'storeSearchAddressSubmited'],
    [events.STORE_CHANGE_LINK_CLICKED, 'storeChangeLinkClicked'],
    [events.STORE_SELECTED, 'storeSelected'],
    [events.STORE_OPENING_HOURS_TOGGLED, 'storeOpeningHoursToggled'],
    [events.STORE_FILTER_TOGGLED, 'storeFilterToggled'],
    [events.STORE_DIRECTORY_LINK_CLICKED, 'storeDirectoryLinkClicked'],
    [events.STORE_DIRECTORY_STORE_LINK_CLICKED, 'storeDirectoryStoreLinkClicked'],
    [events.STORE_MAP_MARKER_CLICKED, 'storeMapMarkerClicked'],
    [events.STORE_DIRECTORY_MAP_LINK_CLICKED, 'storeDirectoryMapLinkClicked'],
    [events.STORE_GET_DIRECTIONS_CLICKED, 'storeGetDirectionsLinkClicked'],
    [events.STORE_DETAILS_LINK_CLICKED, 'storeDetailsLinkClicked'],
    [events.STORE_BOOK_APPOINTMENT_LINK_CLICKED, 'storeBookAppointmentinkClicked']
];

class ServiceBus extends ServiceBusController {
    @on(events.ECOMM_STARTED, { replay: true })
    public onEcommStarted = () => {
        onEvents.forEach(ServiceManager.setDecoratorOnFunction(on));
    };
}

export const serviceBusBaseClass = new ServiceBus() as ServiceBusControllerBody;
