import { BaseService } from '@estee/elc-service';
import { ServiceInitializer } from '@estee/elc-universal-utils';

const { name, version } = __serviceInfo__;

export class AnalyticsService extends BaseService {
    constructor(serviceIntializer: ServiceInitializer) {
        super(name, version, serviceIntializer);
    }
}

export default {
    configStore: 'CONFIG_STORE',
    serviceBusController: 'SERVICE_BUS_CONTROLLER',
    analyticsService: 'ANALYTICS_SERVICE',
    analyticsDriver: 'ANALYTICS_DRIVER',
    eventSubmitter: 'EVENT_SUBMITTER',
    eventRepository: 'EVENT_REPOSITORY',
    dataAggregator: 'DATA_AGGREGATOR'
};
